import React, {useState} from "react";
import {css} from "@emotion/react"
import { Helmet } from "react-helmet";

const QUESTIONS = [
  {
    prompt: "People are saying mean things about you :(",
    img: "madman.jpeg",
    options: [
      {text: 'Make criticizing you illegal', value: -25},
      {text: "Accept it as part of life", value: 0},
      {text: 'Tell everyone they are fake news', value: -10},
    ]
  },
  {
    prompt: "The Republicans want you to help the French and the Federalists want you to fight the French",
    img: "twobuttons.jpeg",
    options: [
      {text: "Go to war with France", value: -10},
      {text: "Kind of try to make peace with them but also build up an army “just in case” and create some tension by being extremely ambiguous", value: -25 },
      {text: "Become an ally of France and fight the British", value: -5},
    ]
  },
  {
    prompt: "You are kind of sort of enemies with France but not really",
    img: "baug.jpeg",
    options: [
      {text: "Treat French immigrants with the same respect as fellow Americans", value:0},
      {text: "Sit in a corner and cry about your life decisions", value: -10},
      {text: "Make a new law that allows you to imprison or deport anyone who seems French", value: -25},
    ]
  },
  {
    prompt: "Some people really don’t want to pay your taxes and have started a rebellion (what a surprise)",
    img: "taxes.jpeg",
    options: [
      {text: "Send troops and crush the rebellion", value: -25},
      {text: "Increase the taxes, and send more tax collectors", value: -50},
      {text: "Have a casual discussion with them", value: 0},
    ]
  },
]

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const USPresidentSimulator = () => {
  const [questionIndex, setQuestionIndex]= useState(0)
  const [prob, setProb] = useState(100)
  const [diff, setDiff] = useState(0)
  const highApproval = prob > 50

  const onSelectOption = (option) => {
    setDiff(option.value)
    setProb(prob + option.value)
    setQuestionIndex(questionIndex+1)
  }

  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 18px;
      padding-bottom: 50px;
    `}>
      <h1 css={css`
        margin-bottom: 10px;
        text-align: center;
      `}>American President Simulator</h1>
      <img css={css`
        height: 100px;

      `} src="/flag15.png" alt="USA Flag (15 states)"></img>

      <h3 css={css`margin-bottom: 5px`}>Your goal is to get a low approval rating</h3>
      <label for="file">Approval Rating ({prob}%)</label>
<progress id="file" value={prob} max="100"> {prob}% </progress>
<div css={css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
`}>

  {questionIndex < QUESTIONS.length ? (
    <>
      <h2 css={css`
        text-align: center;
      `}>{QUESTIONS[questionIndex].prompt}</h2>

  <img css={css`
    max-height: 100px;
    margin-top: 15px;
  `} src={QUESTIONS[questionIndex].img} alt="something funny"></img>
  <div css={css`
    display: flex;
    flex-direction: column;
    align-items: center;
  
  `}>
  {QUESTIONS[questionIndex].options.map((option) => (
    <button css={css`
    margin-top: 20px;
    font-size: 18px;
    padding: 6px;
    cursor: pointer;
    width: 350px;
    word-wrap: break-word;
    `}
    onClick={() => onSelectOption(option)}
    >
     { option.text}
      </button>
  ))}
  </div>
  </>
  ) : !highApproval ?  (
    <div css={css`text-align: center;`}>
      <h2>You have absolutely no chance of getting reelected</h2>
      <img css={css`
        max-width: 400px;
      `} src="/adamsadmin.jpeg"/>
      <h3>Enjoy retirement</h3>
    </div>
  ) : <div css={css`text-align: center;`}>
      <h2>You succeeded at being president, the people loved you</h2>
      <img css={css`
       max-width: 400px;
      `} src="/johnadams.jpg"/>
      <h3>Good job.</h3>
    </div>}

</div>
    </div>
  )
}
  export default USPresidentSimulator;